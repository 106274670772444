import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const FormTable = () => {
    const [forms, setForms] = useState([]);
    const [filteredForms, setFilteredForms] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [agenteFilter, setAgenteFilter] = useState('');
    const [cadeteFilter, setCadeteFilter] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const username = localStorage.getItem('username'); // Obtener el nombre de usuario
        const allowedUsers = ['Naomi', 'Juano'];

        if (!allowedUsers.includes(username)) {
            navigate('/'); // Redirigir si el usuario no está en la lista
            return;
        }

        const fetchForms = async () => {
            try {
                const response = await axios.get(`/api/form`); // Obtener todos los formularios
                setForms(response.data);
                setFilteredForms(response.data);
            } catch (error) {
                console.error("Error fetching forms:", error);
                setErrorMessage('Error al cargar los formularios.');
            }
        };

        fetchForms();
    }, [navigate]);

    useEffect(() => {
        const filtered = forms.filter(form => {
            const agenteMatch = form.agenteNombre.toLowerCase().includes(agenteFilter.toLowerCase());
            const cadeteMatch = form.cadeteNombre.toLowerCase().includes(cadeteFilter.toLowerCase());
            return agenteMatch && cadeteMatch;
        });
        setFilteredForms(filtered);
    }, [agenteFilter, cadeteFilter, forms]);

    return (
        <div>
            <h2>Todos los Formularios</h2>
            {errorMessage && <p className="error">{errorMessage}</p>}
            <div className="filter-container">
                <input
                    type="text"
                    placeholder="Filtrar por Nombre Agente"
                    value={agenteFilter}
                    onChange={(e) => setAgenteFilter(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Filtrar por Nombre Cadete"
                    value={cadeteFilter}
                    onChange={(e) => setCadeteFilter(e.target.value)}
                />
            </div>
            <table className="form-table">
                <thead>
                    <tr>
                        <th>Agente</th>
                        <th>Cadete</th>
                        <th>Conducción</th>
                        <th>Radio</th>
                        <th>Conducta</th>
                        <th>Procedimientos</th>
                        <th>Comentarios</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredForms.length > 0 ? (
                        filteredForms.map((form, index) => (
                            <tr key={index}>
                                <td>{form.agenteNombre}</td>
                                <td>{form.cadeteNombre}</td>
                                <td>{form.conduccion}</td>
                                <td>{form.radio}</td>
                                <td>{form.conducta}</td>
                                <td>{form.procedimientos}</td>
                                <td>{form.comentarios}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="7">No hay formularios que coincidan con los filtros.</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default FormTable;
