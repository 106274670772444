import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const allowedUsers = ['Naomi', 'Juano'];
    
        if (!allowedUsers.includes(username)) {
            console.error(`Acceso no autorizado: ${username} intentando iniciar sesión.`);
            alert('No deberías estar entrando en esta página');
            return; 
        }
    
        try {
            // Llama a la API para iniciar sesión
            const response = await axios.post('https://rtdadmin.derkyu.lol/api/auth/login', { username, password });

            // Si el inicio de sesión es exitoso, redirige
            if (response.status === 200) {
                alert('Inicio de sesión exitoso');
                // Guarda el nombre de usuario en localStorage, si es necesario
                localStorage.setItem('username', username);
                navigate('/form-table'); // Redirección a FormTable.js
            }
        } catch (err) {
            console.error(err);
            alert('Error al iniciar sesión');
        }
    };

    return (
        <div className="auth-container">
            <h2>Inicio de Sesión</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Nombre de Usuario"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />
                <input
                    type="password"
                    placeholder="Contraseña"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <button type="submit">Iniciar Sesión</button>
            </form>
        </div>
    );
};

export default Login;
